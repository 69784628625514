<template>
    <div>
        <div
            v-if="loading"
            class="d-flex flex-column justify-content-center align-items-center"
            style="height: calc(100vh - 4rem)"
        >
            <div v-if="loadingError" class="mb-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="300"
                    height="200"
                    viewBox="0 0 647.059 386.822"
                >
                    <g transform="translate(0 0)">
                        <path
                            d="M826.887,661.518c-8.284-7-13.316-18.058-12-28.823s9.493-20.661,20.095-22.947,22.687,4.042,25.908,14.4c1.772-19.967,3.814-40.77,14.43-57.774,9.612-15.4,26.261-26.413,44.3-28.384s37.041,5.473,48.293,19.714,14.008,34.945,6.127,51.3c-5.806,12.045-16.5,21.008-27.709,28.3a178.835,178.835,0,0,1-122.312,26.758Z"
                            transform="translate(-331.483 -279.041)"
                            fill="#f2f2f2"
                        />
                        <path
                            d="M966.809,545.869A295.086,295.086,0,0,0,880.1,663.11c-.827,2.027,2.464,2.909,3.282.9a291.8,291.8,0,0,1,85.829-115.74c1.7-1.383-.721-3.778-2.406-2.406Z"
                            transform="translate(-348.214 -281.036)"
                            fill="#fff"
                        />
                        <path
                            d="M373.3,643.024c11.378-9.615,18.289-24.8,16.477-39.588s-13.038-28.378-27.6-31.518-31.161,5.552-35.584,19.776c-2.434-27.424-5.239-56-19.82-79.353-13.2-21.147-36.069-36.279-60.852-38.985s-50.875,7.517-66.331,27.078-19.24,48-8.415,70.454c7.974,16.543,22.666,28.855,38.059,38.871a245.63,245.63,0,0,0,168,36.752"
                            transform="translate(-165 -262.449)"
                            fill="#f2f2f2"
                        />
                        <path
                            d="M216.154,484.179a401.884,401.884,0,0,1,56.007,55.615,407.63,407.63,0,0,1,44.168,65.368,405.968,405.968,0,0,1,18.914,40.048c1.136,2.784-3.384,4-4.507,1.243a398.913,398.913,0,0,0-36.45-68.965A401.424,401.424,0,0,0,245.4,517.1a400.481,400.481,0,0,0-32.551-29.612c-2.334-1.9.991-5.19,3.305-3.305Z"
                            transform="translate(-177.058 -265.188)"
                            fill="#fff"
                        />
                        <path
                            d="M695.968,575.936H424.5V297.4A107.593,107.593,0,0,1,531.971,189.931H588.5A107.593,107.593,0,0,1,695.968,297.4Z"
                            transform="translate(-231.498 -189.931)"
                            fill="#f2f2f2"
                        />
                        <path
                            d="M639.443,575.937H424.5V297.4a106.64,106.64,0,0,1,20.524-63.177c.494-.673.986-1.337,1.494-1.994a107.446,107.446,0,0,1,22.873-22.2c.491-.357.983-.708,1.483-1.059a107.218,107.218,0,0,1,23.407-12.241c.492-.186.992-.373,1.492-.552a107.115,107.115,0,0,1,23.137-5.456c.489-.067.991-.119,1.494-.171a108.8,108.8,0,0,1,23.135,0c.5.052,1,.1,1.5.172a107.093,107.093,0,0,1,23.131,5.455c.5.179,1,.366,1.494.552a106.95,106.95,0,0,1,23.135,12.06c.5.343,1,.694,1.492,1.045a108.575,108.575,0,0,1,13.673,11.576,107.314,107.314,0,0,1,9.464,10.822c.506.655,1,1.319,1.491,1.991A106.64,106.64,0,0,1,639.443,297.4Z"
                            transform="translate(-231.498 -189.932)"
                            fill="#ccc"
                        />
                        <path
                            d="M815.485,708.964h-549.1a.886.886,0,1,1,0-1.771h549.1a.886.886,0,1,1,0,1.771Z"
                            transform="translate(-190.752 -322.481)"
                            fill="#3f3d56"
                        />
                        <path
                            d="M599.021,522.786H502.334a4.809,4.809,0,0,1-4.834-4.771V302.2a4.809,4.809,0,0,1,4.834-4.771h96.687a4.809,4.809,0,0,1,4.834,4.771V518.014A4.809,4.809,0,0,1,599.021,522.786Z"
                            transform="translate(-250.204 -217.478)"
                            fill="#fff"
                        />
                        <rect
                            width="106.356"
                            height="1.487"
                            transform="translate(247.296 149.783)"
                            fill="#ccc"
                        />
                        <rect
                            width="106.356"
                            height="1.487"
                            transform="translate(247.668 234.652)"
                            fill="#ccc"
                        />
                        <rect
                            width="1.487"
                            height="226.099"
                            transform="translate(280.764 79.953)"
                            fill="#ccc"
                        />
                        <rect
                            width="1.487"
                            height="226.099"
                            transform="translate(317.952 79.953)"
                            fill="#ccc"
                        />
                    </g>
                </svg>
            </div>
            <p
                class="mb-0 text-danger text-center font-weight-600"
                v-if="loadingError"
            >
                {{ loadingError }}
            </p>
            <img
                v-if="!loadingError"
                class="mb-3"
                width="150"
                src="@/assets/imgs/logo-lg.png"
                alt="DLP"
            />
            <b-spinner
                v-if="!loadingError"
                variant="primary"
                label="Loading..."
            ></b-spinner>
        </div>
        <div v-else>
            <div class="quiz-title">
                <div
                    class="
                        container
                        d-flex
                        flex-column flex-sm-row
                        justify-content-between
                        align-items-center
                    "
                >
                    <h3 class="text-capitalize">{{ quiz.name }}</h3>
                    <div
                        v-if="quiz.expire"
                        class="d-flex flex-row flex-sm-column"
                    >
                        <div class="text-gray mx-2 mx-sm-0">Expiry Date</div>
                        <div>04/20/2021</div>
                    </div>
                </div>
            </div>
            <div
                v-if="quiz.header"
                :style="quiz.header"
                class="quiz-head no-radius"
            ></div>
            <div class="quiz-user-info">
                <div class="container">
                    <div v-if="requiredErorr" class="mb-5">
                        <b-alert show variant="danger">{{
                            requiredErorr
                        }}</b-alert>
                    </div>
                    <!-- quiz -->
                    <div
                        v-for="(question, index) in questions"
                        :key="question.id"
                        class="quiz-body"
                    >
                        <label class="quiz-label text-gray"
                            >Question {{ index + 1 }}</label
                        >
                        <div
                            class="
                                d-flex
                                align-items-center
                                justify-content-between
                            "
                            :class="[question.hint ? '' : 'mb-3']"
                        >
                            <h5 class="text-brand font-weight-600">
                                {{ question.question }}
                            </h5>
                            <span v-if="question.required">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#dc3545"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
                                    />
                                </svg>
                            </span>
                        </div>
                        <div
                            v-if="question.hint"
                            class="d-flex align-items-start mb-4"
                        >
                            <small class="d-flex align-items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 24 24"
                                    fill="#ffc107"
                                >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z"
                                    />
                                </svg>
                                <span class="text-warning">Hint:</span>
                            </small>
                            <small class="mx-2 text-gray">{{
                                question.hint
                            }}</small>
                        </div>
                        <ul class="options">
                            <li
                                v-for="option in question.options"
                                :key="option.op_id"
                            >
                                <label class="control">
                                    <input
                                        @change="
                                            makeAnswer(
                                                question.id,
                                                option.op_id
                                            )
                                        "
                                        type="radio"
                                        :value="option.op_id"
                                        :name="question.id"
                                    />
                                    <span class="checker">
                                        <img
                                            src="@/assets/imgs/check-icon.png"
                                            alt="check"
                                        />
                                    </span>
                                    <span>{{ option.option }}</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div
                                class="
                                    form-group
                                    d-flex
                                    flex-column flex-sm-row
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <div></div>
                                <button
                                    v-if="paginate.nextPageUrl"
                                    @click="getCurrentQuiz()"
                                    class="
                                        btn btn-quiz
                                        text-uppercase
                                        mb-4 mb-sm-0
                                    "
                                    :disabled="loadingNext"
                                >
                                    <b-spinner
                                        v-if="loadingNext"
                                        variant="light"
                                    ></b-spinner>
                                    <span v-else class="text-white">Next</span>
                                </button>
                                <button
                                    v-if="
                                        paginate.currentPage ==
                                            paginate.last_page_id &&
                                        !paginate.nextPageUrl
                                    "
                                    @click="sendQuizAnswers()"
                                    class="
                                        btn btn-quiz
                                        text-uppercase
                                        mb-4 mb-sm-0
                                    "
                                    :disabled="sending"
                                >
                                    <b-spinner
                                        v-if="sending"
                                        variant="light"
                                    ></b-spinner>
                                    <span v-else class="text-white"
                                        >Send Answers</span
                                    >
                                </button>
                                <div v-if="paginate.count">
                                    <span>{{ paginate.currentPage }}</span
                                    >/<span>{{ paginate.last_page_id }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="quiz.footer" class="quiz-footer">
                <div class="container sm-no-padding">
                    <div :style="quiz.footer" class="quiz-footer-img"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    getQuiz, 
    sendQuizAnswers,
    sendUserFormData
} from "@/API/Quiz";
export default {
    name: "Quiz",
    data() {
        return {
            loading: true,
            loadingNext: false,
            sending: false,
            paginate: {
                count: 0,
                currentPage: 1,
                firstItem: 1,
                hasMorePages: false,
                last_page_id: null,
                nextPageUrl: null,
                onFirstPage: true,
                per_page: 1,
                previousPageUrl: null,
            },
            quiz: {
                id: null,
                name: null,
                is_login: 0,
                header: null,
                footer: null,
            },
            questions: [],
            requiredErorr: null,
            loadingError: null,
        };
    },
    computed: {
        user() {
            return this.$store.getters['auth/user']
        },
        code() {
            return this.$route.params.code || 0;
        },
        verified() {
            return this.$route.query.verified || false
        },
        isReplay() {
            return this.$route.query.replay || false
        },
        quizID() {
            return this.$route.query.quiz_id || 0
        },
        partnerID() {
            return this.$route.query.partner_id || 0
        },
        specialityID() {
            return this.$route.query.speciality_id || 0
        },
        answeredQs() {
            return this.$store.state.quiz.answeredQuestions || [];
        },
        responseID() {
            return this.$store.state.quiz.responseID || null;
        },
    },
    methods: {
        async getCurrentQuiz() {
            this.requiredErorr = null;
            this.loadingError = null;

            if (!this.code) {
                this.$router.push("/404");
                return;
            }

            if (!this.checkAllRequiredAnswered()) {
                this.requiredErorr = this.$t("requiredErorr");
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                return;
            }

            if (this.paginate?.nextPageUrl) {
                this.loadingNext = true;
                this.paginate.currentPage = this.paginate.currentPage + 1;
            }

            await getQuiz(this.code, this.paginate?.currentPage).then((res) => {
                if (res?.status == 200) {
                    const responseData = res?.data?.data;
                    // console.log('getting quiz', responseData);

                    this.$store.commit("quiz/UPDATEQUIZDATA", responseData);

                    /* **
                     // check about quiz needs login or not
                    // if is not login and general quiz => user will be direted to form info 
                    **/
                    if (!this.verified) {
                        if (
                            responseData.paginate.onFirstPage &&
                            !responseData.is_login &&
                            !this.$store.state.quiz.userDataCollected
                        ) {
                            this.$router.push(`/quiz?code=${this.code}`);
                        }
                    }

                    this.paginate = responseData?.paginate;
                    this.quiz = responseData;
                    this.quiz.header = `background: url(${responseData.header}) center center no-repeat;`;
                    this.quiz.footer = `background: url(${responseData.footer}) center center no-repeat;`;
                    this.questions = responseData?.question;

                    this.loadingNext = false;
                    this.loading = false;

                    setTimeout(() => {
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }, 1000);

                    return;
                }

                if (res?.status == 404) {
                    this.$router.push("/404");
                    return;
                }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res?.response?.data?.message) {
                    this.loadingError = res.response.data.message;
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        },
        makeAnswer(qID, aID) {
            this.questions = this.questions.map((q) => {
                if (q.id == qID) {
                    q.answer = aID;
                    this.collectAnswers(q);
                }
                return q;
            });
        },
        checkAllRequiredAnswered() {
            let requiredCount = 0;
            let answered = 0;
            this.questions.map((q) => {
                if (q.required) {
                    requiredCount++;
                }
                if (q.required && q.answer) {
                    answered++;
                }
            });
            return requiredCount == answered;
        },
        collectAnswers(q) {
            this.$store.commit("quiz/UPDATEQUESTIONS", q);
        },
        getFormatedAnswersRequestData() {
            let formattedData = {
                response_id: this.responseID,
                question_id: [],
            };

            this.answeredQs.forEach((question) => {
                if (question.required) {
                    formattedData.question_id.push({
                        id: question.id,
                        option_id: question.answer,
                    });
                }
            });

            return JSON.parse(JSON.stringify(formattedData));
        },
        sendQuizAnswers() {
            if (!this.checkAllRequiredAnswered()) {
                this.requiredErorr = this.$t("requiredErorr");
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                return;
            }

            const reqData = this.getFormatedAnswersRequestData();
            this.sending = true;
            sendQuizAnswers(reqData).then((res) => {
                // console.log(res);
                if (res.status == 200) {
                    this.$store.commit("quiz/UPDATERESULT", res.data);
                    let resultURL = `/quiz/${this.code}/result`;
                    if (this.verified)  {
                        resultURL = `/quiz/${this.code}/result?response_id=${this.responseID}&verified=true`;
                    }
                    this.$router.push(resultURL);

                    return;
                }

                if (res?.response?.status == 404) {
                    this.$router.push("/404");
                    return;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res.response?.data?.message) {
                    this.requiredErorr = res.response.data.message;
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        },
    },
    async mounted() {
        if (this.verified && !this.responseID) {
            const verifiedUserData = {
                user_id: this.user.id,
                quiz_id: this.quizID,
                partner_id: parseInt(this.partnerID),
                speciality_id: parseInt(this.specialityID)
            };
            await sendUserFormData(verifiedUserData).then((res) => {
                if (res.status == 200) {
                    // console.log('send user data', res.data);
                    this.$store.commit('quiz/UPDATERESPONSEID', res.data?.details)
                    this.$store.commit("quiz/TOGGLEUSERDATACOLLECTED", {
                        isCollected: true,
                        collectedData: verifiedUserData
                    });
                }
            });

            this.getCurrentQuiz();
            return;
        }
        this.getCurrentQuiz();
    },
};
</script>

